video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(27, 33, 231, 0.2);
  object-fit: contain;
}

.hero-container > h1 {
  text-align: center;
  color: #fff;
  font-size: 100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container {
    align-items: center;
  }
  .hero-container > h1 {
    font-size: 50px;
    text-align: center;
  }
  .hero-container > p {
    text-align: center;
    font-size: 30px;
  }
  .btn-mobile {
    align-items: center;

    text-decoration: none;
  }
}
