.switch-container {
  margin-right: 10px;
  margin-left: 10px;
}
.toggle-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1d2f68;
  border: 1px solid;
  border-radius: 99px;
  width: 50px;
  height: 28px;
  transition: background-color 0.1s ease, border-color 0.2s ease;
  cursor: pointer;
  box-shadow: 1px 1px 10px;
  position: relative;
}
.toggle-btn .thumb {
  height: 20px;
  width: 20px;
  background-color: #fff;
  border-radius: 99px;
  transform: translateX(0);
  transition: left 0.15s ease;
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
}

.toggle-btn.toggled {
  background-color: #e0e6f1;
}
.toggle-btn:hover {
  border-color: #fff;
}
.toggle-btn.toggled .thumb {
  left: calc(50px - 26px);
}
.lang-btn-fr {
  position: relative;
  transform: translateX(0);
  transition: right 0.25s ease;
  right: 9px;
}
.lang-btn-en {
  color: #fff;
  position: relative;
  transform: translateX(0);
  transition: left 0.25s ease;
  left: 9px;
}
