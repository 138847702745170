:root {
  --primary: #1d2f68;
  --secondary: #fff;
  --alternate: #e0e6f1;
}

.footer-container {
  display: flex;
  flex-direction: row;
  background-color: var(--primary);
  padding: 4rem 0 2rem 0;
  justify-content: center;
  text-align: center;
}

.footer-column {
  color: var(--secondary);
  margin: 1em 3em;
  font-size: 13px;
}
.footer-column-logos {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-column-logos > a > img,
.footer-column-logos > img {
  max-width: 100px;
}
.footer-column-heading {
  font-weight: bolder;
  font-size: 30px;
  margin-bottom: 10px;
}

.footer-column-text > span {
  display: block;
  margin-top: 10px;
  margin-bottom: 3px;
  font-size: 17px;
  text-decoration: none;
}
.footer-column-text > span > a {
  display: block;
  margin-top: 20px;
  margin-bottom: 3px;
  font-size: 17px;
  text-decoration: none;
  color: var(--secondary);
}
.footer-column-text > span > a:hover {
  text-decoration: underline;
  color: var(--alternate);
}

@media screen and (max-width: 700px) {
  .footer-container {
    flex-direction: column;
  }
}
