:root {
  --primary: #1d2f68;
  --secondary: #fff;
  --alternate: #e0e6f1;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Tahoma, Verdana, Helvetica, sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /*background-image: url('logo192.png');*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--secondary);
  font-size: 100px;
}

.products {
  /*background-image: url('logo192.png');*/
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: var(--secondary);
  font-size: 100px;
}

.sign-up {
  /*background-image: url('logo192.png');*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: var(--secondary);
  font-size: 100px;
}

.about-us {
  background-image: url('../public/aboutUs.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10%;
}

.contact-us {
  background-image: url('../public/contact.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10%;
}
.page-title {
  font-size: 50px;
}
