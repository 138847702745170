:root {
  --primary: #1d2f68;
  --secondary: #fff;
  --alternate: #e0e6f1;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: var(--alternate);
}
.contact_text {
  margin: 2em;
  align-items: center;
  width: 60%;
  text-align: center;
  line-height: 2em;
}

form {
  margin: 2rem;
  max-width: 700px;
  width: 100%;
  background: var(--primary);
  padding: 25px 25px 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px;
  color: #fff;
}

form h2 {
  font-size: 30px;
  text-align: center;
}

form .input-box {
  margin-top: 20px;
}

.input-box .field {
  width: 100%;
  height: 50px;
  background: transparent;
  border: 2px solid #ddd;
  border-radius: 5px;
  outline: none;
  padding: 15px;
  font-size: 16px;
  color: #fff;
  margin-top: 8px;
}

.input-box .field.mess {
  height: 200px;
  resize: none;
}

form button {
  width: 100%;
  height: 55px;
  background-color: var(--alternate);
  border: none;
  border-radius: 6px;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: var(--primary);
  font-weight: 500;
  margin-top: 25px;
  cursor: pointer;
}

form button:hover {
  background-color: #fff;
  color: #1d2f68;
  transition: 0.1s ease-in;
}
