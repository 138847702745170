html {
  width: 100%;
}

.about_us-container {
  background-color: var(--alternate);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about_us-section {
  margin: 2em;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about_us-section:nth-child(1) {
  margin-top: 0;
}
.about_us-section:nth-child(odd) {
  background: var(--secondary);
  border-radius: 0px 50px 50px 50px;
}

.about_us-text {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  font-size: 18px;
  line-height: 2em;
}
.about_us-text_with_list {
  flex-direction: column;
}
.about_us-text_with_list > ul {
  margin: 3em;
  flex-direction: column;
  list-style: none;
  font-weight: bolder;
  line-height: 2em;
}
.about_us-text_with_list > ul > li {
  margin: 3em;
}
.about_us-img {
  margin: 2em;
  max-width: 650px;
  min-width: 600px;
  align-self: center;
}
.about_us-img > img {
  width: 100%;
  height: auto;
}
.text-title {
  align-self: center;
  margin: 1rem 2rem 4rem 2rem;
  color: #1d2f68;
  font-size: 30px;
}
.text_chunk-text {
  margin: 3rem;
  align-self: center;
}
.text_chunk-wrapper {
  display: flex;
  flex-direction: row;
}
.text_chunk-img {
  padding: 2em;
  max-width: 450px;
  height: auto;
}

@media screen and (max-width: 1250px) {
  .about_us-text {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }
  .about_us-img {
    min-width: 250px;
  }
}
